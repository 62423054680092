<template>
  <Modal v-model="modalSelectProduct" title="配置单品信息" footer-hide :mask-closable="false" width="1200">
    <Row :gutter="8" class="m-b-10">
      <i-col span="8">
        <Select v-model="assetIds" multiple size="small" placeholder="选择资产">
          <Option v-for="item in assetArray" :key="'assetId_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="10">
        <i-input v-model="query.keyword" size="small" placeholder="输入关键字"></i-input>
      </i-col>
      <i-col span="6">
        <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch">搜索</i-button>
      </i-col>
    </Row>

    <Table size="small" stripe :data="list" :columns="tableColumns"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber"
        @on-change="handlePageChange"></Page>
    </div>
  </Modal>
</template>

<script>
import { getPublisherProductAssetList } from '@/api/product/asset'
import { toMoney } from '@/utils/wnumb_own'
import { getCommonPage } from '@/api/product/productskuedit'

export default {
  data () {
    return {
      modalSelectProduct: false,
      existedProducts: [],

      assetIds: [], // 用于页面数据绑定， 如果是空，那么查询条件要全部带上值
      assetArray: [],
      query: {
        assetIds: [],
        enabled: true,
        keyword: '',
        pageSize: 15,
        pageNumber: 1
      },
      list: [],
      total: 0,
      tableColumns: [
        { title: '主键', align: 'center', width: '80', key: 'id' },
        { title: '名称', align: 'center', key: 'name' },
        {
          title: '媒介使用费（块/天）',
          align: 'center',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, toMoney(params.row.usePrice))
          }
        },
        {
          title: '媒介使用费（4周）',
          align: 'center',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, toMoney(params.row.usePrice * 28))
          }
        },
        {
          title: '服务费',
          align: 'center',
          minWidth: 160,
          key: 'produceCost',
          render: (h, params) => {
            if (params.row.producePrice && params.row.producePrice.producePrices.length) {
              const html = []
              params.row.producePrice.producePrices.forEach(item => {
                html.push(h('p', {
                  attrs: {
                    class: 'money'
                  }
                }, `${item.name}-刊例价：${toMoney(item.produceCost)}/${item.unitName}-结算价：${toMoney(item.produceStatement)}/${item.unitName}`))
              })
              return html
            } else {
              return h('span', {
                attrs: {
                  class: 'money'
                }
              }, '/')
            }
          }
        },
        {
          title: '所属资产',
          align: 'center',
          render: (h, params) => {
            const html = []
            params.row.assets.forEach(item => {
              html.push(h('p', item.name))
            })
            return h('div', html)
          }
        },
        { title: '等级', align: 'center', key: 'levelName' },
        { title: '最小连续售卖天数', align: 'center', key: 'minday' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            if (this.existedProducts.find(x => x.id === params.row.id)) {
              return h('a', {
                style: { color: '#ef4f4f' },
                on: {
                  click: () => {
                    this.handleRemove(params.row)
                  }
                }
              }, '移除')
            } else {
              return h('a', {
                on: {
                  click: () => {
                    this.handleAdd(params.row)
                  }
                }
              }, '添加')
            }
          }
        }
      ]
    }
  },
  mounted () {
    getPublisherProductAssetList().then((res) => {
      if (res && !res.errcode) {
        this.assetArray = res
      }
    })
  },
  methods: {
    showModal (selectedProducts) {
      this.modalSelectProduct = true
      this.existedProducts = selectedProducts

      this.getRoutineMediumData()
    },
    getRoutineMediumData () {
      const assetIds = this.assetIds.length === 0 ? this.assetArray.map(x => x.id) : this.assetIds
      this.query.assetIds = JSON.stringify(assetIds)
      getCommonPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getRoutineMediumData()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.getRoutineMediumData()
    },
    handleRemove (params) {
      const dataIndex = this.existedProducts.findIndex(x => x.id === params.id)
      this.existedProducts.splice(dataIndex, 1)
    },
    handleAdd (params) {
      this.existedProducts.push({
        id: params.id,
        name: params.name
      })
    }
  },
  watch: {
    modalSelectProduct (val) {
      if (!val) {
        this.$emit('update-products', this.existedProducts)
      }
    }
  }
}
</script>
