<template>
  <div>
    <Modal v-model="modalModifyItem" :title="isAdd?'新增成本项':'编辑成本项'" :mask-closable="false" width="1000">
      <Form ref="serviceItemData" id="serviceItemData" :model="serviceItemData" :rules="ruleValidate" label-position="top">
        <Row :gutter="16">
          <i-col span="12">
            <FormItem label="成本项名称" prop="name">
              <Input size="small" v-model="serviceItemData.name" placeholder="请填写成本项名称" />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="任务类型" prop="costType">
              <Select v-model="serviceItemData.costType" size="small" placeholder="请选择运维任务类型">
                <Option v-for="item in taskItemTypes" :key="item.value" :value="item.value">{{item.name}}</Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>

        <Row :gutter="16">
          <i-col span="12">
            <FormItem label="设备类型" prop="devicemodel">
              <Select v-model="serviceItemData.devicemodel" size="small" placeholder="请选择设备类型">
                <Option v-for="item in deviceModelList" :key="item.id" :value="item.id">{{item.name}}</Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="是否为默认项" prop="defaultSelect">
              <Checkbox v-model="serviceItemData.defaultSelect">设置为默认项</Checkbox>
            </FormItem>
          </i-col>
        </Row>

        <FormItem label="单品名称" prop="skuIdList">
          <Tag v-for="(product,i) in productRules" type="border" color="black"
            closable :key="i" @on-close="handleRemoveProduct(i)">{{product.name}}</Tag>

          <p><a @click="handleAddProductRule"><Icon type="md-add" size="16"/>添加新的单品</a></p>
        </FormItem>

        <div>
          <p class="remark">供应商成本费用</p>
          <inventoryItem v-for="(rule,index) in serviceRules" :key="index"
            allowEdit :content="rule" :editIndex="index" @edit="handleEditServiceRule" @delete="handleDeleteServiceRule"/>

          <p><a @click="handleAddServiceRule"><Icon type="md-add" size="16"/>添加新的供应商成本费用</a></p>
        </div>
      </Form>

      <div slot="footer" >
        <Button class="btn-black m-r-5" style="width:60px" size="small" @click="modalModifyItem = false">取消</Button>
        <Button class="m-l-8" type="success" size="small" @click="handleSubmitServiceItem">保存成本项</Button>
      </div>
    </Modal>

    <!-- 配置产品规则 -->
    <selectProducts ref="selectProducts" @update-products="handleUpdateProductRule"/>
    <!-- 添加成本项 -->
    <modifyServiceRule ref="modifyServiceRule" @update-sevice-rule="handleUpdateServiceRule"/>
  </div>
</template>

<script>
import selectProducts from './SelectProducts'
import modifyServiceRule from './ModifyServiceRule'
import inventoryItem from './InventoryItem'

import { addServiceItem, getServiceItem } from '@/api/msp/serviceitem'

export default {
  components: {
    selectProducts, modifyServiceRule, inventoryItem
  },
  data () {
    return {
      modalModifyItem: false,
      isAdd: true, // 是否添加操作
      companyId: this.$store.getters.token.userInfo.companyId,
      publisherId: this.$store.getters.token.userInfo.publisherId,

      serviceItemData: {
        costType: 0, // 任务类型ID
        defaultSelect: true,
        devicemodel: 0, // 设置ID
        id: undefined,
        name: '', // 配置想名称
        serviceItemDetailDtoList: '',
        skuIdList: [] // 产品清单
      },
      ruleValidate: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },

      serviceRules: [], // 当前服务规则项
      productRules: [] // 产品规则
    }
  },
  created () {
    this.initBaseData()
  },
  methods: {
    initBaseData () {
      this.$store.dispatch('getDeviceModelData').then(res => {
        this.serviceItemData.devicemodel = res[0].id
      })

      this.$store.dispatch('getTaskItemTypesData').then(res => {
        this.serviceItemData.costType = res[0].value
      })
    },
    showModal (editBean) {
      this.modalModifyItem = true

      if (editBean) {
        this.isAdd = false

        this.serviceItemData.costType = editBean.costType // 任务类型ID
        this.serviceItemData.defaultSelect = editBean.defaultSelect
        this.serviceItemData.devicemodel = editBean.devicemodel // 设置ID
        this.serviceItemData.id = editBean.id
        this.serviceItemData.name = editBean.name // 配置想名称

        // 设置产品规则
        this.productRules = editBean.skuVOList.map(item => {
          return {
            id: item.skuId,
            name: item.skuName
          }
        })

        // 产品清单
        this.serviceRules = editBean.serviceItemDetailDtoList.map(item => {
          item.itemInventoryDtoList = JSON.parse(JSON.stringify(item.inventoryVOList))
          return item
        })
      } else {
        this.isAdd = true

        this.serviceItemData = {
          costType: this.taskItemTypes[0].value, // 任务类型ID
          defaultSelect: true,
          devicemodel: this.deviceModelList[0].id, // 设置ID
          id: undefined,
          name: '', // 配置想名称
          serviceItemDetailDtoList: '',
          skuIdList: [] // 产品清单
        }
        this.productRules = []
        this.serviceRules = []
      }
    },
    handleAddServiceRule () {
      const addBean = {
        costTypeName: this.taskItemTypes.find(x => x.value === this.serviceItemData.costType).name,
        deviceModelName: this.deviceModelList.find(x => x.id === this.serviceItemData.devicemodel).name
      }
      this.$refs.modifyServiceRule.showModal(true, addBean)
    },
    handleEditServiceRule (serviceRuleBean, index) {
      const updateBean = {
        costTypeName: this.taskItemTypes.find(x => x.value === this.serviceItemData.costType).name,
        deviceModelName: this.deviceModelList.find(x => x.id === this.serviceItemData.devicemodel).name
      }
      this.$refs.modifyServiceRule.showModal(false, updateBean, serviceRuleBean, index)
    },
    handleUpdateServiceRule (params) {
      if (params.isAdd) {
        this.serviceRules.push(params.serviceRuleBean)
      } else {
        this.serviceRules.splice(params.editIndex, 1, params.serviceRuleBean)
      }
    },
    handleDeleteServiceRule (index) {
      this.serviceRules.splice(index, 1)
    },
    handleAddProductRule () {
      this.$refs.selectProducts.showModal(this.productRules)
    },
    handleUpdateProductRule (params) {
      this.productRules = params
    },
    handleRemoveProduct (index) {
      this.productRules.splice(index, 1)
    },
    handleSubmitServiceItem () {
      this.$refs.serviceItemData.validate((valid) => {
        if (valid) {
          this.serviceItemData.serviceItemDetailDtoList = this.serviceRules
          this.serviceItemData.skuIdList = this.productRules.map(x => x.id)

          addServiceItem(this.serviceItemData).then(res => {
            if (this.isAdd) {
              this.$emit('add-success')
              this.$Notice.success({ desc: '添加成本项成功！' })
            } else {
              this.$Notice.success({ desc: '编辑成本项成功！' })
              this.$emit('update-success')
            }

            this.modalModifyItem = false
          })
        }
      })
    }
  },
  computed: {
    deviceModelList () { // 设备类型清单
      return this.$store.state.serviceConfig.deviceModelListData
    },
    taskItemTypes () { // 任务类型
      return this.$store.state.serviceConfig.taskItemTypesData
    }
  },
  watch: {
    beginUpdateLeft () {
      // if (this.leftPageType === 'create') {
      //   this.resetPage()
      // } else if (this.leftPageType === 'edit') {
      // 获取当前配置项的信息
      getServiceItem({ serviceItemId: this.editId }).then(res => {
        this.serviceItemData = res
        this.serviceRules = res.serviceItemDetailDtoList
      })
      // }
    }
  }
}
</script>
