<template>
  <Modal v-model="modalModifyServiceRule" :mask-closable="false" width="1000">
    <p slot="header">
        {{ isAdd?'新增':'编辑' }}供应商成本费用（<span class="text-orange text-bold">{{ modalTitle }}</span>）
    </p>

    <Row class="m-b-10">
      <i-col span="12">
        <p class="title m-b-5">运维公司</p>
        <Select v-model="serviceItemDetailDtoList.companyId" size="small" style="width:250px" placeholder="请选择运维公司">
          <Option v-for="item in maintenanceCompanyList" :key="item.id" :value="item.id">{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="12">
        <p class="title m-b-5">设置为任务默认供应商</p>
        <i-switch v-model="serviceItemDetailDtoList.defaultCompany">
          <Icon type="md-checkmark" slot="open"></Icon>
          <Icon type="md-close" slot="close"></Icon>
        </i-switch>
      </i-col>
    </Row>
    <Row class="m-b-10">
      <i-col span="24">
        <p class="title m-b-5">运维资产 <span class="text-red">*</span></p>
        <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">
          <Checkbox
              :indeterminate="indeterminateAsset"
              :value="checkAllAsset"
              @click.prevent.native="handleCheckAllAsset">全选</Checkbox>
        </div>
        <CheckboxGroup v-model="serviceItemDetailDtoList.assetIdList" @on-change="handleChangeAssets">
            <Checkbox v-for="asset in assetArray" :key="asset.id" :label="asset.id">{{asset.name}}</Checkbox>
        </CheckboxGroup>
      </i-col>
    </Row>

    <Row class="m-b-20">
      <i-col span="24">
        <p class="title m-b-5">运维区域 <span class="text-red">*</span></p>
        <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">
          <Checkbox
              :indeterminate="indeterminatePosition"
              :value="checkAllPosition"
              @click.prevent.native="handleCheckAllPosition">全选</Checkbox>
        </div>
        <CheckboxGroup v-model="serviceItemDetailDtoList.positionList" @on-change="handleChangePosition">
            <Checkbox v-for="(item,index) in positionArray" :key="index" :label="item.value">{{item.name}}</Checkbox>
        </CheckboxGroup>
      </i-col>
    </Row>

    <Row class="m-b-20">
      <i-col span="24">
        <p class="title m-b-5">成本类型 <span class="text-red">*</span></p>
        <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">
          <RadioGroup v-model="serviceItemDetailDtoList.feeType" @on-change="handleChangeFeeType">
            <Radio v-for="item in feeTypeArray" :key="item.value" :label="item.value">{{item.name}}</Radio>
          </RadioGroup>
        </div>

        <template v-if="serviceItemDetailDtoList.feeType===1">
          <Row class="m-b-5 m-t-5" v-if="serviceItemDetailDtoList.itemInventoryDtoList.length>0">
            <i-col span="12" class="title">明细名称</i-col>
            <i-col span="6" class="title">金额</i-col>
            <i-col span="5" class="title">单位</i-col>
          </Row>
          <Row v-for="(item,i) in serviceItemDetailDtoList.itemInventoryDtoList" :key="i" class="m-b-5">
            <i-col span="12">
              <Input v-model="item.feeName" size="small" style="width: 300px;" placeholder="请填写明细名称" />
            </i-col>
            <i-col span="6">
              <InputNumber
                :max="1000000"
                size="small"
                style="width:100px"
                v-model="item.price"
                :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\￥\s?|(,*)/g, '')"></InputNumber>
            </i-col>
            <i-col span="5">
              <Select size="small" v-model="item.unit" style="width:100px" transfer>
                <Option v-for="unit in unitList" :key="unit.value" :value="unit.value">{{unit.name}}</Option>
              </Select>
            </i-col>
            <i-col span="1">
              <a v-show="serviceItemDetailDtoList.itemInventoryDtoList.length>1" @click="handleRemoveInventoryItem(i)" ><Icon type="ios-trash-outline" size="20" color="red"/></a>
            </i-col>
          </Row>
          <p class="m-t-5">
            <a @click="hanldeAddInventoryItem"><Icon type="md-add" size="16"/>添加费用明细</a>
          </p>
        </template>

        <template v-else>
          <Row class="m-b-5 m-t-5" v-if="serviceItemDetailDtoList.itemInventoryDtoList.length>0">
            <i-col span="7" class="title">明细名称</i-col>
            <i-col span="7" class="title">材质</i-col>
            <i-col span="3" class="title">金额</i-col>
            <i-col span="3" class="title">单位</i-col>
            <i-col span="3" class="title">质保有效期（天）</i-col>
          </Row>
          <Row v-for="(item,i) in serviceItemDetailDtoList.itemInventoryDtoList" :key="i" class="m-b-5">
            <i-col span="7">
              <Input v-model="item.feeName" size="small" style="width: 230px;" placeholder="请填写明细名称" />
            </i-col>
            <i-col span="7">
              <Select v-model="item.materialId" size="small" style="width: 230px;" placeholder="请选择制作工艺材质" transfer>
                <Option v-for="material in materialList" :key="material.id" :value="material.id">{{material.name}}</Option>
              </Select>
            </i-col>
            <i-col span="3">
              <InputNumber
                :max="1000000"
                size="small"
                style="width:100px"
                v-model="item.price"
                :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\￥\s?|(,*)/g, '')"></InputNumber>
            </i-col>
            <i-col span="3">
              <Select size="small" v-model="item.unit" style="width:100px" transfer>
                <Option v-for="unit in unitList" :key="unit.value" :value="unit.value">{{unit.name}}</Option>
              </Select>
            </i-col>
            <i-col span="3">
              <InputNumber :min="0" :max="10000" style="width:100px" size="small" v-model="item.period"></InputNumber>
            </i-col>
            <i-col span="1">
              <a v-show="serviceItemDetailDtoList.itemInventoryDtoList.length>1" @click="handleRemoveInventoryItem(i)" ><Icon type="ios-trash-outline" size="20" color="red"/></a>
            </i-col>
          </Row>
          <p class="m-t-5">
            <a @click="hanldeAddInventoryItem"><Icon type="md-add" size="16"/>添加费用明细</a>
          </p>
        </template>
      </i-col>
    </Row>

    <div slot="footer" >
      <Button class="btn-black m-r-5" style="width:60px" size="small" @click="modalModifyServiceRule = false">取消</Button>
      <Button class="m-l-8" type="success" style="width:60px" size="small" :disabled="isCanSubmit" @click="handleSubmitServiceRule">{{isAdd?'新增':'保存'}}</Button>
    </div>
  </Modal>
</template>

<script>
import em from '@/utils/enum'
import { cloneDeep } from 'lodash'
import { getCompanyList } from '@/api/os/company'
import { getChargeStatus } from '@/api/msp/status'
import { getPublisherAssetList } from '@/api/rim/asset'
import { getMaterialList } from '@/api/msp/serviceitem'

export default {
  data () {
    return {
      modalModifyServiceRule: false,
      modalTitle: '',
      isAdd: true, // 是否添加操作
      editIndex: 0,

      unitList: [], // 计费单位
      feeTypeArray: em.maintenanceFeeTypeEnum,
      maintenanceCompanyList: [], // 运维公司清单
      materialList: [], // 工艺材质清单

      serviceItemDetailDtoList: { // 服务项包含的公司信息
        assetIdList: [], // 资产集合
        companyId: null, // 供应商id
        defaultCompany: false, // 默认供应商
        feeType: 1, // 费用类型
        positionList: [], // 位置集合
        id: undefined,
        serviceId: undefined,
        itemInventoryDtoList: [], // 费用明细

        companyName: '',
        assetNameList: [],
        positionNameList: []
      },

      assetArray: [],
      indeterminateAsset: false,
      checkAllAsset: false,

      positionArray: em.positionEnum,
      indeterminatePosition: false,
      checkAllPosition: false
    }
  },
  created () {
    this.initBaseData()
  },
  methods: {
    initBaseData () {
      // 获取工艺材质清单
      getMaterialList().then(res => {
        this.materialList = res
      })
      // 获取供应商清单
      const postData2 = {
        publisherId: this.$store.getters.token.userInfo.publisherId,
        enabled: true,
        type: 3
      }
      getCompanyList(postData2).then(res => {
        this.maintenanceCompanyList = res
      })
      // 获取计价单位清单
      getChargeStatus().then(res => {
        this.unitList = res
      })
      // 获取全部运维线路
      this.getAssetData()
    },
    async getAssetData () {
      const result = await getPublisherAssetList() || []
      this.assetArray = [...result]
    },
    showModal (isAdd, titleBean, editBean, editIndex) {
      this.isAdd = isAdd
      this.modalModifyServiceRule = true
      this.modalTitle = titleBean.deviceModelName + ' - ' + titleBean.costTypeName
      this.editIndex = editIndex

      if (isAdd) {
        this.serviceItemDetailDtoList = { // 服务项包含的公司信息
          assetIdList: [], // 资产集合
          companyId: this.maintenanceCompanyList[0].id, // 供应商id
          defaultCompany: false, // 默认供应商
          feeType: 1, // 费用类型
          positionList: [], // 位置集合
          id: undefined,
          serviceId: undefined,
          itemInventoryDtoList: [] // 费用明细
        }

        // 设置全选资产和位置
        this.checkAllAsset = false
        this.indeterminateAsset = false
        this.checkAllPosition = false
        this.indeterminatePosition = false
        this.hanldeAddInventoryItem()
      } else {
        this.serviceItemDetailDtoList = cloneDeep(editBean)
        // 需要兼容旧版本，如果包含了-1，那么重新选择全线路
        if (this.serviceItemDetailDtoList.assetIdList.includes(-1)) {
          this.serviceItemDetailDtoList.assetIdList = this.assetArray.map(x => x.id)
        }

        // 设置全选资产和位置
        this.checkAllAsset = (this.serviceItemDetailDtoList.assetIdList.length === this.assetArray.length)
        this.indeterminateAsset = !this.checkAllAsset
        this.checkAllPosition = (this.serviceItemDetailDtoList.positionList.length === this.positionArray.length)
        this.indeterminatePosition = !this.checkAllPosition
      }
    },

    hanldeAddInventoryItem () { // 添加费用项明细
      this.serviceItemDetailDtoList.itemInventoryDtoList.push({
        feeName: '',
        inventoryId: undefined, // 材质
        materialId: 1,
        materialName: '',
        period: undefined, // 保质期
        price: 0,
        serviceItemId: undefined,
        startTime: undefined,
        endTime: undefined,
        unit: 1,
        unitName: ''
      })
    },
    handleRemoveInventoryItem (index) { // 移除费用项
      this.serviceItemDetailDtoList.itemInventoryDtoList.splice(index, 1)
    },
    handleSubmitServiceRule () {
      // 获取显示文本字段
      this.serviceItemDetailDtoList.companyName = this.maintenanceCompanyList.find(x => x.id === this.serviceItemDetailDtoList.companyId).name
      this.serviceItemDetailDtoList.assetNameList = []
      this.serviceItemDetailDtoList.assetIdList.forEach(element => {
        this.serviceItemDetailDtoList.assetNameList.push(
          this.assetArray.find(x => x.id === element).name
        )
      })
      this.serviceItemDetailDtoList.positionNameList = []
      this.serviceItemDetailDtoList.positionList.forEach(element => {
        this.serviceItemDetailDtoList.positionNameList.push(
          this.positionArray.find(x => x.value === element).name
        )
      })

      // 格式化费用明细的成本项
      this.serviceItemDetailDtoList.itemInventoryDtoList.forEach(element => {
        element.unitName = this.unitList.find(x => x.value === element.unit).name
        // 获取材质的文本字段， 后端需要存储
        if (this.serviceItemDetailDtoList.feeType !== 1) {
          element.materialName = this.materialList.find(x => x.id === element.materialId).name
        }
      })

      this.$emit('update-sevice-rule',
        {
          isAdd: this.isAdd,
          editIndex: this.editIndex,
          serviceRuleBean: this.serviceItemDetailDtoList
        }
      )
      this.modalModifyServiceRule = false
    },
    handleCheckAllAsset () {
      if (this.indeterminateAsset) {
        this.checkAllAsset = false
      } else {
        this.checkAllAsset = !this.checkAllAsset
      }
      this.indeterminateAsset = false

      if (this.checkAllAsset) {
        this.serviceItemDetailDtoList.assetIdList = this.assetArray.map(x => x.id)
      } else {
        this.serviceItemDetailDtoList.assetIdList = []
      }
    },
    handleChangeAssets (data) {
      if (data.length === this.assetArray.length) {
        this.indeterminateAsset = false
        this.checkAllAsset = true
      } else if (data.length > 0) {
        this.indeterminateAsset = true
        this.checkAllAsset = false
      } else {
        this.indeterminateAsset = false
        this.checkAllAsset = false
      }
    },
    handleCheckAllPosition () {
      if (this.indeterminatePosition) {
        this.checkAllPosition = false
      } else {
        this.checkAllPosition = !this.checkAllPosition
      }
      this.indeterminatePosition = false

      if (this.checkAllPosition) {
        this.serviceItemDetailDtoList.positionList = this.positionArray.map(x => x.value)
      } else {
        this.serviceItemDetailDtoList.positionList = []
      }
    },
    handleChangePosition (data) {
      if (data.length === this.positionArray.length) {
        this.indeterminatePosition = false
        this.checkAllPosition = true
      } else if (data.length > 0) {
        this.indeterminatePosition = true
        this.checkAllPosition = false
      } else {
        this.indeterminatePosition = false
        this.checkAllPosition = false
      }
    },
    handleChangeFeeType () {
      this.serviceItemDetailDtoList.itemInventoryDtoList = []
      this.hanldeAddInventoryItem()
    }
  },
  computed: {
    isCanSubmit () {
      return this.serviceItemDetailDtoList.assetIdList.length === 0 || this.serviceItemDetailDtoList.positionList.length === 0
    }
  }
}
</script>
