<template>
  <Card :bordered="false" class="m-t-5 m-b-10" >
    <p slot="title">{{content.companyName}}</p>
    <div v-if="allowEdit" slot="extra">
      <a @click="handleEditServiceRule">编辑</a>
      |
      <a @click="handleDeleteServiceRule" class="text-red">删除</a>
    </div>

    <Row class="p-b-5">
      <i-col span="12">
        <span class="title">成本类型：</span>
        <Tag v-if="content.feeType===1" color="blue">人工费</Tag>
        <Tag v-else color="volcano">材料费</Tag>
      </i-col>
      <i-col span="12">
        <span class="title">默认供应商：</span>
        <Icon v-if="content.defaultCompany" type="md-checkmark" size="16" color="#19be6b"/>
        <Icon v-else type="md-close" size="16" color="#ed3f14"/>
      </i-col>
    </Row>
    <p class="p-b-5">
      <span class="title">运维资产：</span>
      <span v-for="(asset,i) in content.assetNameList" :key="i">
        {{ asset }} <span v-if="i<content.assetNameList.length-1" class="m-l-2 m-r-2 remark">|</span>
      </span>
    </p>
    <p class="p-b-5">
      <span class="title">运维区域：</span>
      <span v-for="(position,i) in content.positionNameList" :key="i">
        {{ position }} <span v-if="i<content.positionNameList.length-1" class="m-l-2 m-r-2 remark">|</span>
      </span>
    </p>
    <Divider dashed style="margin: 0;">费用明细</Divider>
    <template v-if="content.feeType===1">
      <p v-for="(rule,index) in content.itemInventoryDtoList" :key="index">
        {{ rule.feeName }}：{{ formatMoney(rule.price) }} / {{ rule.unitName }}
      </p>
    </template>
    <template v-else>
      <Row>
        <i-col span="6" class="title">明细名称</i-col>
        <i-col span="6" class="title">材质</i-col>
        <i-col span="2" class="title">金额</i-col>
        <i-col span="2" class="title">单位</i-col>
        <i-col span="5" class="title">开始结束时间</i-col>
        <i-col span="2" class="title">质保有效期</i-col>
      </Row>
      <Row v-for="(rule,index) in content.itemInventoryDtoList" :key="index">
        <i-col span="6">{{rule.feeName}}</i-col>
        <i-col span="6">{{rule.materialName}}</i-col>
        <i-col span="2">{{formatMoney(rule.price)}}</i-col>
        <i-col span="2">{{rule.unitName}}</i-col>
        <i-col span="5">{{rule.startTime}} - {{ rule.endTime }}</i-col>
        <i-col span="2">{{rule.period}}</i-col>
      </Row>
    </template>

  </Card>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    content: {
      type: Object,
      required: true
    },
    allowEdit: {
      type: Boolean,
      default: false
    },
    editIndex: {
      type: Number,
      required: true
    }
  },
  methods: {
    handleEditServiceRule () {
      this.$emit('edit', this.content, this.editIndex)
    },
    handleDeleteServiceRule () {
      this.$emit('delete', this.editIndex)
    },
    formatMoney (number) {
      return toMoney(number)
    }
  }
}
</script>
