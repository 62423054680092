/**
 * 合同客户类型枚举
 */
const AdvertiserTypeEnum = [
  { value: 1, name: '广告主' },
  { value: 2, name: '广告公司' },
  { value: 3, name: '4A公司' },
  { value: 5, name: '小微客户' },
  { value: 4, name: '一类公益' },
  { value: 7, name: '二类公益' },
  { value: 8, name: '三类公益' }
]

/**
 * 上刊设置详情页显示的类型
 */
const installShowType = [
  { value: 1, name: '上刊设置' },
  { value: 2, name: '下刊' },
  { value: 3, name: '监播' }
]

const installShowTaskType = [
  { value: 1, name: '上刊' },
  { value: 2, name: '监播' },
  { value: 7, name: '下刊' }
]
/**
 * 运维作业工单状态
 */
const workOrderStatus = [
  { value: 0, name: '已派单' },
  { value: 1, name: '执行中' },
  { value: 2, name: '已完成' }
]

/**
 * 运维制作工单状态
 */
const taskStatus = [
  { value: 0, name: '待处理' },
  { value: 1, name: '已派单' },
  { value: 2, name: '执行中' },
  { value: 3, name: '已完成' }
]

/**
 * 站点内位置信息
 */
const positionEnum = [
  { value: -1, name: '全部位置' },
  { value: 0, name: '站台' },
  { value: 1, name: '轨行区' },
  { value: 2, name: '站厅' },
  { value: 4, name: '通道' },
  { value: 5, name: '楼梯' },
  { value: 6, name: '换乘通道' },
  { value: 8, name: '街道' }
]

/**
 * 运维单位
 */
const maintenanceUnitEnum = [
  { value: 1, name: '块' },
  { value: 2, name: '面积' }
]
/**
 * 运维费用类型
 */
const maintenanceFeeTypeEnum = [
  { value: 1, name: '人工费' },
  { value: 2, name: '材料费' }
]

export default {
  AdvertiserTypeEnum,
  installShowType,
  workOrderStatus,
  taskStatus,
  installShowTaskType,
  positionEnum,
  maintenanceUnitEnum,
  maintenanceFeeTypeEnum
}
